import { UNASSIGNED } from './constants';

export const getTasksByStatus = (tasks, status) => tasks.filter((task) => task.status === status);

export const getTaskById = (tasks, id) => tasks.find((task) => task.id === id);

export const updateUsersStatus = (users, labelersObject) => {
  // Iterate through the array of users
  users.forEach((user) => {
    // Iterate through each labeler section in the labelersObject

    for (const section in labelersObject.labelers) {
      // Find the user in the current labeler section
      const correspondingLabeler = labelersObject.labelers[section].find(
        (labeler) => labeler.labeler_id === user.id.toString()
      );

      // If a corresponding labeler is found, update the user's status
      if (correspondingLabeler) {
        user.status = section.toLocaleUpperCase();
        user.first_name = correspondingLabeler.first_name;
        user.last_name = correspondingLabeler.last_name;
      }
    }
    for (const section in labelersObject.reviewers) {
      // Find the user in the current reviewer section
      const correspondingReviewer = labelersObject.reviewers[section].find(
        (labeler) => labeler.labeler_id === user.id.toString()
      );

      // If a corresponding labeler is found, update the user's status
      if (correspondingReviewer && correspondingReviewer.status !== UNASSIGNED) {
        user.status = section.toLocaleUpperCase();
        user.first_name = correspondingReviewer.first_name;
        user.last_name = correspondingReviewer.last_name;
        break; // Exit the loop once a match is found
      } else if (!user.status || user.status === 'unassigned') {
        user.status = UNASSIGNED;
      }
    }
  });
  return users;
};
