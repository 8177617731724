import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { CropListPreviewButton } from './CropListPreviewButton';
import { CropListLoadMoreButton } from './CropListLoadMoreButton';

function CropLists({ crops, selectedCrops, setSelectedCrop, onPreviewClicked, isRejected = false }) {
  const cropLimit = 20;
  const [visibleCrops, setVisibleCrops] = useState(cropLimit);
  const remainingCrops = crops.length - visibleCrops;

  const showMoreCrops = () => {
    setVisibleCrops((prevVisibleCrops) => prevVisibleCrops + cropLimit);
  };

  const resetComponent = () => {
    setVisibleCrops(cropLimit);
  };

  useEffect(() => {
    resetComponent();
  }, [crops]);

  const styles = isRejected ? { border: '2px solid #FF4A55' } : null;

  return (
    <div>
      {crops.length ? (
        <Card style={styles}>
          <Card.Body>
            {crops.slice(0, visibleCrops).map((crop) => (
                <span className="crop-list-container" key={crop.id}
                      onClick={() => setSelectedCrop(crop.id)}
                      style={{
                        border:
                            selectedCrops && selectedCrops.length && selectedCrops.includes(crop.id)
                                ? '3px solid red'
                                : '3px solid transparent'
                      }}
                >
                <div>
                    <img src={crop.crop_path} alt="crop"/>
                </div>
                <CropListPreviewButton
                    onClick={onPreviewClicked}
                    crop={crop}
                />
              </span>
            ))}
          </Card.Body>
          {remainingCrops > 0 && (
            <Card.Footer>
              <CropListLoadMoreButton onClick={showMoreCrops} remainingCrops={remainingCrops} />
            </Card.Footer>
          )}
        </Card>
      ) : null}
    </div>
  );
}

export default CropLists;
