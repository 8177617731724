import React, { useState, useEffect } from 'react';
import { Button, Col, Alert } from 'react-bootstrap';
import Table from 'components/Table/Table';
import { getInternalClusters, getExternalClusters, reProcess } from 'api/clusters';
import { getJobTypes } from 'api/jobTypes';
import { getLabelers } from 'api/user';
import ModalWithImage from 'components/Modal/ModalWithImage';
import { PAGE_SIZE } from 'components/Paginators/ListPaginator';
import ModalWithReProcess from 'components/Modal/ModalWithReProcess';
import moment from 'moment';
import ClusterFiltersExternal from './ClusterFiltersExternal';
import ClusterFilersInternal from './ClusterFilersInternal';
import ClusterDetails from './ClusterDetails';
import { useUserContext } from '../../contexts/Users';
import SpinnerButton from '../../components/Buttons/SpinnerButton';

function Clusters() {
  const { isAdmin, isViewer } = useUserContext();
  const [showClusterFinderDetails, setshowClusterFinderDetails] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rowNames, setRowNames] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [clusterId, setClusterId] = useState(null);
  const [labelers, setLabelers] = useState([{}]);
  const [reviewers, setRewiewers] = useState([{}]);
  const [jobTypes, setJobTypes] = useState([{}]);
  const [jobTypesExternal, setJobTypesExternal] = useState([{}]);
  const [count, setCount] = useState(0);
  const [next, setNext] = useState(null);
  const [previous, setPrevious] = useState(null);
  const [page, setPage] = useState(1);
  const [parameters, setParameters] = useState({});
  const [showReProcessModal, setShowReProcessModal] = useState(false);
  const [updatedData, setUpdatedData] = useState([]);
  const [prevAction, setPrevAction] = useState(null);
  const [selectedExternal, setSelectedExternal] = useState(null);
  const [selectedInternal, setSelectedInternal] = useState(null);
  const [paramsCSV, setParamsCSV] = useState({});
  const [errorMessageCSV, setErrorMessageCSV] = useState('');
  const [messageCSVInternal, setMessageCSVInternal] = useState('');
  const [messageCSVExternal, setMessageCSVExternal] = useState('');
  const [infoCSV, setInfoCSV] = useState({
    isSuccess: false,
    isDisabledButton: true
  });

  useEffect(() => {
    let isMounted = true;
    getJobTypes({ for_clustering: true, page_size: 999 }).then(async (response) => {
      if (isMounted) {
        const data = await response.json();
        const jobTypeList = data.results;
        const jobTypes = [];
        const jobTypesExternal = [];
        jobTypeList.forEach((el) => {
          jobTypes.push({
            value: el.predefined_classes_id,
            label: `${el.name}-${el.predefined_classes_id}`
          });
          jobTypesExternal.push({
            value: el,
            label: `${el.name}-${el.predefined_classes_id}`
          });
        });
        setJobTypes(jobTypes);
        setJobTypesExternal(jobTypesExternal);
      }
    });

    getLabelers().then(async (response) => {
      if (isMounted) {
        const data = await response.json();
        const allLabelers = data.results;
        const lebelers = [];
        const reviewers = [];
        allLabelers.forEach((labeler) => {
          const option = { value: labeler.id, label: `${labeler.user.username}-${labeler.id}` };
          lebelers.push(option);
          if (labeler.permission === 'QC') reviewers.push(option);
          if (labeler.permission === 'STAFF') reviewers.push(option);
        });
        setLabelers(lebelers);
        setRewiewers(reviewers);
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    rowNames.forEach((item) => {
      const checkbox = document.getElementById(item.id);
      if (checkbox) {
        checkbox.checked = selectedItems.includes(item.id);
      }
    });
  }, [selectedItems, rowNames]);

  const handleCheckboxChange = (id) => {
    setSelectedItems((prevSelectedItems) => {
      const updatedSelection = prevSelectedItems.includes(id)
        ? prevSelectedItems.filter((item) => item !== id)
        : [...prevSelectedItems, id];
      return updatedSelection;
    });
  };

  const handleSelectAll = () => {
    const allIds = rowNames.map((item) => {
      selectedItems.length !== rowNames.length;
      return item.id;
    });
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.length === allIds.length ? [] : allIds
    );
  };

  const getParams = (selected) => {
    const {
      selectedJobTypeInternal,
      selectedJobTypeExternal,
      selectedState,
      selectedLabeler,
      subState,
      searchClusterIdInternal,
      searchClusterIdExternal,
      searchBb,
      selectedReviewer
    } = selected;

    if (prevAction === getInternalClusters) {
      setSelectedInternal(selected);
    } else {
      setSelectedExternal(selected);
    }

    const params = {
      page_size: PAGE_SIZE
    };
    if (selectedJobTypeInternal?.value) params.predefined_classes = selectedJobTypeInternal.value;
    if (selectedJobTypeExternal?.value?.predefined_classes_id)
      params.predefined_classes = selectedJobTypeExternal.value.predefined_classes_id;
    if (selectedState?.value) params.state = selectedState.value;
    if (selectedLabeler?.value) params.labeler_id = selectedLabeler.value;
    if (subState?.value) params.substate = subState.value;
    if (searchClusterIdInternal) params.cluster_id = searchClusterIdInternal;
    if (searchClusterIdExternal) params.cluster_id = searchClusterIdExternal;
    if (searchBb) params.brandbank_uuid = searchBb;
    if (selectedReviewer?.value) params.reviewer_id = selectedReviewer.value;
    if (selected?.page) params.page = selected.page;
    return params;
  };

  const showPreview = (imageUrl) => {
    setShowModal(true);
    setImageUrl(imageUrl);
  };

  const showDetails = (clusterId) => {
    setClusterId(clusterId);
    setshowClusterFinderDetails(true);
  };

  const handleRequest = (params, action = prevAction) => {
    const actionFunction = action === 'internal' ? getInternalClusters : getExternalClusters;
    const parameters = getParams(params);
    setLoading(true);
    actionFunction(parameters).then(async (response) => {
      setLoading(false);
      const data = await response.json();
      if (response.status !== 200) {
        setRowNames([]);
        setParamsCSV({});
        setErrorMessage(data.detail || 'Error');
        return;
      }
      if (data && !data.results) return;

      const newParamsCSV = {
        parameters: {
          ...parameters,
          page: 1,
          page_size: data.count,
          generate_report: true
        },
        actionFunction: action === 'internal' ? getInternalClusters : getExternalClusters
      };
      setInfoCSV((prev) => ({
        ...prev,
        isSuccess: false
      }));
      setParamsCSV(newParamsCSV);
      const rowNames = [];
      setErrorMessage('');
      setErrorMessageCSV('');
      data.results.forEach((item) => {
        const obj = {
          '': (
            <input
              id={item.id}
              type="checkbox"
              name={item.id}
              onChange={() => handleCheckboxChange(item.id)}
            />
          ),
          id: item.id,
          jobType: item.job_type_id,
          bb_uuid: item.brandbank_uuid,
          state: item.state,
          'sub-state': item.substate,
          size: item.count_of_points,
          labeler: item.labeler_id,
          reviewer: item.reviewer_id,
          actions: (
            <>
              <Button onClick={() => showPreview(item.preview_image_path)} variant="defoult">
                <i className="fa fa-eye" />
              </Button>
              <Button onClick={() => showDetails(item.id)} className="ml-2" variant="defoult">
                <i className="fa fa-info-circle" />
              </Button>
            </>
          )
        };
        rowNames.push(obj);
      });
      setCount(data.count);
      setNext(data.next);
      setPrevious(data.previous);
      setRowNames(rowNames);
      setPage(parameters.page);
    });
  };

  const handleRequestCSV = (params, action) => {
    const actionFunction = action === 'internal' ? getInternalClusters : getExternalClusters;
    const actionFunctionMessage =
      action === 'internal' ? setMessageCSVInternal : setMessageCSVExternal;
    const parameters = getParams(params);
    const parametersCSV = {
      ...parameters,
      page: 1,
      generate_report: true
    };
    delete parametersCSV.page_size;
    setLoading(true);
    actionFunction(parametersCSV).then(async (response) => {
      if (response.status !== 204) {
        actionFunctionMessage('Request CSV Error');
      } else {
        actionFunctionMessage('Your request has been sent, in a few minutes please check e-mail');
      }
      setLoading(false);
    });
  };
  const filterCallback = (params, action) => {
    if (params?.isCSVRequest) {
      handleRequestCSV(params, action);
      return;
    }
    setPrevAction(action);
    setInfoCSV({
      isSuccess: false,
      isDisabledButton: false
    });
    const parameters = { ...params, page: 1 };
    handleRequest(parameters, action);
    setParameters(parameters, action);
  };

  const loadData = (params) => {
    setPage(params.page);
    handleRequest({ ...parameters, ...params });
  };

  const columnNamesViewer = [
    'id',
    'jobType',
    'bb_uuid',
    'state',
    'sub-state',
    'size',
    'labeler',
    'reviewer',
    'actions'
  ];

  const columnNames = isViewer ? columnNamesViewer : ['', ...columnNamesViewer];

  const updateSelectedClusters = () => {
    reProcess({ clusters_ids: selectedItems }).then(async (response) => {
      if (response.status === 200) {
        const data = await response.json();
        setUpdatedData(data);
        setShowReProcessModal(true);
        setSelectedItems([]);
      } else {
        const data = await response.json();
        setErrorMessage(data.detail || 'Error');
      }
    });
  };

  const requestCSV = () => {
    const { actionFunction, parameters } = paramsCSV;
    setLoading(true);
    actionFunction(parameters).then(async (response) => {
      if (response.status !== 204) {
        setErrorMessageCSV('Request CSV Error');
        setLoading(false);
        return;
      }
      setInfoCSV({ isSuccess: true, isDisabledButton: true });
      setLoading(false);
    });
  };

  return (
    <div>
      {showClusterFinderDetails ? (
        <Button
          size="sm"
          className="ml-4"
          variant="defoult"
          onClick={() => setshowClusterFinderDetails(false)}
        >
          <i className="fas fa-arrow-left" />
        </Button>
      ) : null}
      {showClusterFinderDetails ? (
        <ClusterDetails clusterId={clusterId} />
      ) : (
        <Col md="12">
          <ModalWithImage
            imageUrl={imageUrl}
            showModal={showModal}
            handleClose={() => setShowModal(false)}
          />
          <ModalWithReProcess
            show={showReProcessModal}
            data={updatedData}
            handleClose={() => {
              handleRequest(parameters);
              setShowReProcessModal(false);
            }}
          />
          <ClusterFilersInternal
            prevSelected={selectedInternal}
            jobTypes={jobTypes}
            labelers={labelers}
            reviewers={reviewers}
            filterCallback={filterCallback}
            isLoading={loading}
            messageCSVInternal={messageCSVInternal}
            setMessageCSVInternal={setMessageCSVInternal}
          />
          <ClusterFiltersExternal
            prevSelected={selectedExternal}
            jobTypesExternal={jobTypesExternal}
            labelers={labelers}
            reviewers={reviewers}
            filterCallback={filterCallback}
            isLoading={loading}
            messageCSVExternal={messageCSVExternal}
            setMessageCSVExternal={setMessageCSVExternal}
          />
          {errorMessage ? <Alert variant="danger">{errorMessage}</Alert> : null}
          <Table
            page={page}
            next={next}
            count={count}
            previous={previous}
            selectedItems={selectedItems}
            columnNames={columnNames}
            rowNames={rowNames}
            loadData={loadData}
          />
          {rowNames.length ? (
            <div className="text-center">
              {!isViewer && (
                <>
                  <SpinnerButton
                    disabled={false}
                    className="btn"
                    onClick={handleSelectAll}
                    isLoading={loading}
                    value={selectedItems.length === rowNames.length ? 'Unselect All' : 'Select All'}
                  />
                  <SpinnerButton
                    disabled={!selectedItems.length}
                    className="btn ml-2"
                    onClick={updateSelectedClusters}
                    isLoading={loading}
                    value="Send to processing"
                  />
                </>
              )}
              {(isAdmin || isViewer) && (
                <SpinnerButton
                  className="btn ml-2"
                  onClick={requestCSV}
                  disabled={!paramsCSV?.parameters || infoCSV.isDisabledButton}
                  isLoading={loading}
                  variant="default"
                  value="Request CSV"
                />
              )}

              {infoCSV.isSuccess && (
                <div className="t-center pt-2">
                  Your request has been sent, in a few minutes please check e-mail
                </div>
              )}
              {errorMessageCSV ? (
                <Alert variant="danger" className="mt-4 mb-0">
                  {errorMessageCSV}
                </Alert>
              ) : null}
            </div>
          ) : null}
        </Col>
      )}
    </div>
  );
}

export default Clusters;
