import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import SpinnerButton from 'components/Buttons/SpinnerButton';
import SelectSubState from '../../components/Select/SelectSubState';

function ClusterFilersInternal({
  filterCallback,
  isLoading,
  jobTypes,
  labelers,
  reviewers,
  prevSelected,
  messageCSVInternal,
  setMessageCSVInternal
}) {
  const [state, setState] = useState({
    searchBb: '',
    searchClusterIdInternal: ''
  });
  const [selectedJobTypeInternal, setSelectedJobType] = useState(null);
  const [selectedReviewer, setSelectedReviewer] = useState([]);
  const [selectedLabeler, setSelectedLabeler] = useState([]);
  const [selectedSubState, setSelectedSubState] = useState(null);
  const [validationError, setValidationError] = useState('');

  useEffect(() => {
    if (prevSelected) {
      setSelectedJobType(prevSelected.selectedJobTypeInternal);
      setSelectedReviewer(prevSelected.selectedReviewer);
      setSelectedLabeler(prevSelected.selectedLabeler);
      setSelectedSubState(prevSelected.selectedSubState);
      setState(prevSelected.state);
      setState((prev) => ({ ...prev, searchBb: prevSelected.searchBb }));
      setState((prev) => ({
        ...prev,
        searchClusterIdInternal: prevSelected.searchClusterIdInternal
      }));
    }
  }, [prevSelected]);

  useEffect(() => {
    setMessageCSVInternal('');
  }, [selectedLabeler, state, selectedJobTypeInternal, selectedReviewer, selectedSubState]);

  const onClick = () => {
    filterCallback(
      {
        selectedLabeler,
        searchBb: state.searchBb,
        selectedJobTypeInternal,
        searchClusterIdInternal: state.searchClusterIdInternal,
        selectedReviewer,
        subState: selectedSubState
      },
      'internal'
    );
  };

  const onClickCSV = () => {
    filterCallback(
      {
        selectedLabeler,
        searchBb: state.searchBb,
        selectedJobTypeInternal,
        searchClusterIdInternal: state.searchClusterIdInternal,
        selectedReviewer,
        subState: selectedSubState,
        isCSVRequest: true
      },
      'internal'
    );
  };

  const validateUUID = (uuid) => {
    const uuidRegex =
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

    if (!uuid || uuidRegex.test(uuid)) {
      setValidationError('');
    } else {
      setValidationError('Invalid UUID format');
    }
  };

  const setField = (field, value) => {
    setState((prev) => ({ ...prev, [field]: value }));
    if (field === 'searchBb') validateUUID(value);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minWidth: 180,
      maxWidth: 850,
      minHeight: 50
    })
  };

  const isDisabled =
    isLoading || !!validationError || !(selectedJobTypeInternal || state.searchClusterIdInternal);

  return (
    <Card className="strpied-tabled-with-hover pb-2">
      <Card.Header>
        <Card.Title as="h4">Filters Internal:</Card.Title>
      </Card.Header>
      <Card.Body className="table-full-width table-responsive pl-3 pr-4 mt-n3">
        <Row>
          <Select
            value={selectedJobTypeInternal}
            styles={customStyles}
            onChange={setSelectedJobType}
            isDisabled={isLoading}
            options={jobTypes}
            placeholder="Select Job Type"
            isSearchable
            isClearable
            className="ml-3 mt-3"
            name="id2"
          />
          <SelectSubState
            selectedSubState={selectedSubState}
            setSelectedSubState={setSelectedSubState}
            isDisabled={isLoading}
            className="ml-3 mt-3"
          />
          <Select
            value={selectedLabeler}
            styles={customStyles}
            onChange={setSelectedLabeler}
            options={labelers}
            isDisabled={isLoading}
            placeholder="Select Labelers"
            isSearchable
            className="ml-3 mt-3"
            required
            isClearable
          />
          <Select
            value={selectedReviewer}
            styles={customStyles}
            onChange={setSelectedReviewer}
            options={reviewers}
            isDisabled={isLoading}
            placeholder="Select Reviewers"
            isSearchable
            className="ml-3 mt-3"
            required
            isClearable
          />
          <div className="ml-3 mt-3">
            <Form.Control
              style={{ width: '180px', height: '50px' }}
              disabled={isLoading}
              type="search"
              onChange={(event) => setField('searchClusterIdInternal', event.target.value)}
              className="form-control"
              placeholder="Search on C. ID"
              name="searchClusterIdInternal"
              value={state.searchClusterIdInternal}
            />
          </div>
          <div className="ml-3 mt-3">
            <SpinnerButton
              style={{ height: '50px' }}
              isLoading={isLoading}
              disabled={isDisabled}
              onClick={onClick}
              value="search internal"
            />
          </div>
          <div className="ml-3 mt-3">
            <SpinnerButton
              className="btn"
              style={{ height: '50px' }}
              onClick={onClickCSV}
              isLoading={isLoading}
              disabled={isDisabled}
              value="Request CSV"
            />
          </div>
        </Row>
        {messageCSVInternal && <div className="mt-3">{messageCSVInternal}</div>}
      </Card.Body>
    </Card>
  );
}

ClusterFilersInternal.propTypes = {
  filterCallback: PropTypes.func,
  isLoading: PropTypes.bool,
  jobTypes: PropTypes.array,
  labelers: PropTypes.array,
  reviewers: PropTypes.array,
  prevSelected: PropTypes.object
};

ClusterFilersInternal.defaultProps = {
  filterCallback: () => {},
  isLoading: false,
  jobTypes: [],
  labelers: [],
  reviewers: [],
  prevSelected: {}
};

export default ClusterFilersInternal;
