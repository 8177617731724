/* eslint no-plusplus: "off" */

import React from 'react';
import PropTypes from 'prop-types';
import ListItem from './ListItem';

export const PAGE_SIZE = 20;

const startingPage = (current, lastPage) => {
  let page;
  if (current > 6) {
    if (current + 3 >= lastPage) {
      page = current - 5;
    } else {
      page = current - 3;
    }
  } else {
    page = 2;
  }
  return page;
};

const getPageNumber = (url) => {
  let page = 1;
  if (url) {
    page = parseInt(new URL(url).searchParams.get('page'));
    page = isNaN(page) ? 1 : page;
  }
  return page;
};

const renderList = (total, current, changePage, pageSize) => {
  const pageNumber = pageSize || PAGE_SIZE;
  const lastPage = parseInt(total / pageNumber + (total % pageNumber > 0 ? 1 : 0));

  const startPage = startingPage(current, lastPage);
  const stopPage = lastPage > startPage + 6 ? startPage + 6 : lastPage;

  const items = [];

  items.push(
    <ListItem
      onClick={changePage}
      key={crypto.randomUUID()}
      text={1}
      disabled={false}
      active={current === 1}
    />
  );

  if (current >= 7) {
    items.push(<ListItem key={crypto.randomUUID()} text="..." disabled />);
  }

  for (let i = startPage; i < stopPage; i++) {
    items.push(
      <ListItem
        key={crypto.randomUUID()}
        onClick={changePage}
        text={i}
        disabled={false}
        active={current === i}
      />
    );
  }

  if (current + 4 < lastPage) {
    items.push(<ListItem key={crypto.randomUUID()} text="..." disabled />);
  }

  items.push(
    <ListItem
      key={crypto.randomUUID()}
      onClick={changePage}
      text={lastPage}
      disabled={false}
      active={current === lastPage}
    />
  );

  return items;
};

function ListPaginator({ total, current, next, previous, changePage, pageSize }) {
  const items = renderList(total, current, changePage, pageSize);
  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination">
        <ListItem
          onClick={changePage}
          text="Previous"
          disabled={!previous}
          value={getPageNumber(previous)}
        />
        {items}
        <ListItem onClick={changePage} text="Next" disabled={!next} value={getPageNumber(next)} />
      </ul>
    </nav>
  );
}

ListPaginator.propTypes = {
  total: PropTypes.number,
  current: PropTypes.number,
  next: PropTypes.string,
  previous: PropTypes.string,
  changePage: PropTypes.func
};

ListPaginator.defaultProps = {
  total: 1,
  current: 1,
  next: undefined,
  previous: undefined,
  changePage: () => {}
};

export default ListPaginator;
