import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import SpinnerButton from 'components/Buttons/SpinnerButton';
import CropProducts from '../CropLabelingProcess/CropProducts';
import { useBatchLabelingContext } from '../../contexts/BatchLabelingProcess';
import DialogModal from '../../components/Modal/Modal';
import SelectState from '../../components/Select/SelectState';

function ModalSearch({ modalShow, setModalShow, selectedJobType, setField }) {
  const [selectedLabel, setSelectedLabel] = useState('');
  const [disableButton, setDisableButton] = useState(true);

  const handleClose = () => setModalShow(false);

  const onSelect = (el) => {
    setField('searchBb', el.id);
    setDisableButton(false);
  };

  return (
    <DialogModal
      title="Brand Bank search"
      size="lg"
      body={
        <div className="mt-n4">
          <CropProducts
            onChange={(selectedLabel) => setSelectedLabel(selectedLabel)}
            selectedLabel={selectedLabel}
            selectedJobType={selectedJobType}
            onSelect={onSelect}
          />
        </div>
      }
      handleClose={handleClose}
      handleApprove={handleClose}
      show={modalShow}
      approve="Use it"
      disabled={disableButton}
    />
  );
}

function ClusterFiltersExternal({
  filterCallback,
  isLoading,
  jobTypesExternal,
  prevSelected,
  messageCSVExternal,
  setMessageCSVExternal
}) {
  const [state, setState] = useState({
    searchBb: '',
    searchClusterIdExternal: ''
  });
  const [selectedJobTypeExternal, setSelectedJobType] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedReviewer, setSelectedReviewer] = useState([]);
  const [selectedLabeler, setSelectedLabeler] = useState([]);
  const [validationError, setValidationError] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [isDisabledBBSearch, setIsDisabledBBSearch] = useState(true);

  const { clearSKU, handleJobTypeChange, clearSelectedJobType } = useBatchLabelingContext();

  useEffect(() => {
    if (prevSelected) {
      setSelectedJobType(prevSelected.selectedJobTypeExternal);
      setSelectedState(prevSelected.selectedState);
      setSelectedReviewer(prevSelected.selectedReviewer);
      setSelectedLabeler(prevSelected.selectedLabeler);
      setState(prevSelected.state);
      setState((prev) => ({ ...prev, searchBb: prevSelected.searchBb }));
      setState((prev) => ({
        ...prev,
        searchClusterIdExternal: prevSelected.searchClusterIdExternal
      }));
    }
  }, [prevSelected]);

  useEffect(() => {
    setMessageCSVExternal('');
  }, [selectedLabeler, state, selectedJobTypeExternal, selectedState, selectedReviewer]);

  const onClick = () => {
    filterCallback(
      {
        selectedLabeler,
        searchBb: state.searchBb,
        selectedJobTypeExternal,
        searchClusterIdExternal: state.searchClusterIdExternal,
        selectedState,
        selectedReviewer
      },
      'external'
    );
  };

  const onClickCSV = () => {
    filterCallback(
      {
        selectedLabeler,
        searchBb: state.searchBb,
        selectedJobTypeExternal,
        searchClusterIdExternal: state.searchClusterIdExternal,
        selectedState,
        selectedReviewer,
        isCSVRequest: true
      },
      'external'
    );
  };

  const validateUUID = (uuid) => {
    const uuidRegex =
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

    if (!uuid || uuidRegex.test(uuid)) {
      setValidationError('');
    } else {
      setValidationError('Invalid UUID format');
    }
  };

  const setField = (field, value) => {
    setState((prev) => ({ ...prev, [field]: value }));
    if (field === 'searchBb') validateUUID(value);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minWidth: 180,
      maxWidth: 850,
      minHeight: 50
    })
  };

  const isDisabled =
    isLoading ||
    !!validationError ||
    !(selectedJobTypeExternal || state.searchBb || state.searchClusterIdExternal);

  const onClickJobType = (jobType) => {
    setSelectedJobType(jobType);
    clearSKU();
    if (jobType) {
      handleJobTypeChange(jobType.value);
      setIsDisabledBBSearch(false);
    } else {
      clearSelectedJobType();
      setSelectedJobType(null);
      setIsDisabledBBSearch(true);
    }
  };

  return (
    <Card className="strpied-tabled-with-hover pb-2">
      <Card.Header>
        <Card.Title as="h4">Filters External:</Card.Title>
      </Card.Header>
      <Card.Body className="table-full-width table-responsive pl-3 pr-4 mt-n3">
        <Row>
          <Select
            value={selectedJobTypeExternal}
            styles={customStyles}
            onChange={(el) => onClickJobType(el)}
            isDisabled={isLoading}
            options={jobTypesExternal}
            placeholder="Select Job Type"
            isSearchable
            isClearable
            className="ml-3 mt-3"
            name="id1"
          />
          <SelectState
            selectedState={selectedState}
            setSelectedState={setSelectedState}
            isDisabled={isLoading}
            className="ml-3 mt-3"
          />
          <div style={{ height: '50px' }} className="ml-3 mt-3 text-nowrap">
            <div style={{ width: '180px', height: '50px' }} className="d-inline-block">
              <Form.Control
                style={{ width: '180px', height: '50px', borderRadius: '4px 0 0 4px' }}
                disabled={isLoading}
                type="search"
                onChange={(event) => setField('searchBb', event.target.value)}
                className="form-control"
                placeholder="Search on BB UUID"
                name="searchBb"
                isInvalid={!!validationError}
                value={state.searchBb}
              />
              <Form.Control.Feedback type="invalid">{validationError}</Form.Control.Feedback>
            </div>
            <Button
              onClick={() => setModalShow(true)}
              size="md"
              variant="defoult"
              disabled={isDisabledBBSearch || isLoading}
              style={{
                height: '100%',
                borderRadius: '0 4px 4px 0',
                marginLeft: '-2px',
                verticalAlign: 'top'
              }}
              title="Brand Bank search"
            >
              <i style={{ fontWeight: 'bold' }} className="fa fa-search" />
            </Button>
          </div>
          <div className="ml-3 mt-3">
            <Form.Control
              style={{ width: '180px', height: '50px' }}
              disabled={isLoading}
              type="search"
              onChange={(event) => setField('searchClusterIdExternal', event.target.value)}
              className="form-control"
              placeholder="Search on C. ID"
              name="searchClusterIdExternal"
              value={state.searchClusterIdExternal}
            />
          </div>
          <div className="ml-3 mt-3">
            <SpinnerButton
              style={{ height: '50px' }}
              isLoading={isLoading}
              disabled={isDisabled}
              onClick={onClick}
              value="search external"
            />
          </div>
          <div className="ml-3 mt-3">
            <SpinnerButton
              className="btn"
              style={{ height: '50px' }}
              onClick={onClickCSV}
              isLoading={isLoading}
              disabled={isDisabled}
              value="Request CSV"
            />
          </div>
        </Row>
        {messageCSVExternal && <div className="mt-3">{messageCSVExternal}</div>}
        <ModalSearch
          modalShow={modalShow}
          setModalShow={setModalShow}
          selectedJobType={selectedJobTypeExternal}
          setField={setField}
        />
      </Card.Body>
    </Card>
  );
}

ClusterFiltersExternal.propTypes = {
  filterCallback: PropTypes.func,
  isLoading: PropTypes.bool,
  jobTypes: PropTypes.array,
  labelers: PropTypes.array,
  reviewers: PropTypes.array,
  prevSelected: PropTypes.object
};

ClusterFiltersExternal.defaultProps = {
  filterCallback: () => {},
  isLoading: false,
  jobTypes: [],
  labelers: [],
  reviewers: [],
  prevSelected: {}
};

export default ClusterFiltersExternal;
