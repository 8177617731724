import React, { useState, useEffect } from 'react';
import { Row, Col, Alert } from 'react-bootstrap';
import { assigneUser } from 'api/clusters';
import Switch from 'components/Buttons/Switch';
import JobTypeDropdown from 'components/Buttons/JobTypeDropdown';
import CropProducts from 'views/CropLabelingProcess/CropProducts';
import { useUserContext } from 'contexts/Users';
import useSettings from 'contexts/SettingsContext';
import { getJobTypes } from 'api/jobTypes';
import { useBatchLabelingContext } from 'contexts/BatchLabelingProcess';
import CropLists from 'components/CropLists/CropLists';
import ClusterViewHeader from './ClusterViewHeader';
import CleaningButtons from './CleaningButtons';
import IdentificationButtons from './IdentificationButtons';
import { CropPreview } from './CropPreview';
import CropDraftLists from '../../components/CropLists/CropDraftLists';
import RejectErrorComponent from '../../components/CropLists/RejectErrorComponent';
import FastLaneButtons from './FastLaneButtons';
import FastLaneGPT from './FastLineGPT';

function ClusterWrapper({ action }) {
  const [jobTypes, setJobTypes] = useState([]);
  const [selectedJobType, setSelectedJobType] = useState(null);
  const [selectedLabel, setSelectedLabel] = useState('');
  const [data, setData] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedCrops, setSelectedCrops] = useState([]);
  const [selectedUuid, setSelectedUuid] = useState(null);
  const [selectedSku, setSelectedSku] = useState(false);
  const [previewCrop, setPreviewCrop] = useState(null);
  const [stopLoading, setStopLoading] = useState(false);
  const [prevSelectedLabelName, setPrevSelectedLabelName] = useState(null);
  const [isRejected, setIsRejected] = useState(false);

  const { isAutoLoadOn, setAutoLoad, isLabelerMode, setLabelerMode } = useSettings();
  const { isLabeler, isExternal, assignJobTypes, setAssignJobTypes } = useUserContext();
  const { predefinedSet, clearSKU, handleJobTypeChange } = useBatchLabelingContext();

  useEffect(() => {
    setStopLoading(false);
  }, []);

  let userType;
  if (isExternal) return;
  if (isLabeler) {
    userType = 'labeler';
  } else {
    userType = isLabelerMode ? 'labeler' : 'reviewer';
  }

  const clearPrevSelectedLabelName = () => {
    setSelectedLabel('');
    setSelectedUuid(null);
    setPrevSelectedLabelName('');
  };

  const reCountClusterId = (newCrops) => {
    const cropsListID = [0, ...newCrops.map((el) => el.clusterID)];
    const makeUniq = (arr) => arr.filter((el, id) => arr.indexOf(el) === id);
    const cropsListUniq = makeUniq(cropsListID).sort((a, b) => a - b);
    return {
      newCropsReCount: newCrops.map((el) => ({
        ...el,
        clusterID: cropsListUniq.indexOf(el.clusterID)
      })),
      newClusterCount: cropsListUniq.length
    };
  };

  const assignLabeler = async (jobType) => {
    if (!jobType) return;
    setStopLoading(true);
    setSelectedJobType(jobType);
    setIsRejected(false);
    let checkSubState;

    if (action === 'CLEANING' && userType === 'reviewer') {
      checkSubState = 'CLEANING_QC';
    } else if (action === 'IDENTIFICATION' && userType === 'reviewer') {
      checkSubState = 'IDENTIFICATION_QC';
    } else {
      checkSubState = action;
    }

    try {
      const response = await assigneUser(userType, {
        predefined_classes: jobType.predefined_classes_id,
        substate: checkSubState
      });
      if (response.status === 200) {
        const responseData = await response.json();
        let responseDataUpdated;
        const isDataEmpty = Object.keys(data).length === 0;

        if (checkSubState !== 'CLEANING' || isDataEmpty || data.id !== responseData.id) {
          const responseDataPoints = responseData.points.map((el, index) => ({
            ...el,
            clusterID: +0,
            sortNumber: index
          }));
          responseDataUpdated = {
            ...responseData,
            points: [...responseDataPoints],
            clusterCount: 1
          };
        } else {
          const prevCrops = data.points;
          const responseDataPoints = responseData.points.map((el, index) => {
            const prevClusterId = prevCrops.find((prevCrop) => prevCrop.id === el.id);
            const newClusterId = prevClusterId ? prevClusterId.clusterID : +0;
            const newSortNumber = prevClusterId ? prevClusterId.sortNumber : index;
            return { ...el, clusterID: newClusterId, sortNumber: newSortNumber };
          });

          const { newCropsReCount, newClusterCount } = reCountClusterId(responseDataPoints);
          responseDataUpdated = {
            ...responseData,
            points: [...newCropsReCount],
            clusterCount: newClusterCount
          };
        }

        setData(responseDataUpdated);
        const newReject = action === 'FAST_LANE' ? false : responseDataUpdated.reject_flag;
        setIsRejected(newReject);
        setErrorMessage('');
      } else if (response.status === 204) {
        setData({});
        setErrorMessage('There are no clusters to proceed');
      } else {
        const responseData = await response.json();
        setData({});
        setErrorMessage(responseData.detail);
      }
    } catch (error) {
      console.error('Error assigning user:', error);
    } finally {
      setStopLoading(false);
      if (action!=="FAST_LANE") {
        clearPrevSelectedLabelName();
      }
    }
  };

  useEffect(() => {
    setSelectedCrops([]);
    setData({});
  }, [isLabelerMode]);

  useEffect(() => {
    if (predefinedSet.length && data.brandbank_uuid) {
      predefinedSet.forEach((crop) => {
        if (crop.uuid === data.brandbank_uuid) {
          setSelectedSku(true);
          setSelectedLabel(crop.name);
          setSelectedUuid(data.brandbank_uuid);
        }
      });
    }
  }, [predefinedSet, JSON.stringify(data)]);

  useEffect(() => {
    const fetchJobTypes = async () => {
      try {
        const response = await getJobTypes({ for_clustering: true, page_size: 999 });
        const data = await response.json();
        const jobTypeList = data.results;

        if (isLabeler) {
          setAssignJobTypes();
        }

        setJobTypes(jobTypeList);
      } catch (error) {
        console.error('Error fetching job types:', error);
      }
    };

    fetchJobTypes();
  }, [isLabeler]);

  useEffect(() => {
    if (!assignJobTypes.length) return;
    const assignedJobTypes = jobTypes.filter((jobType) => jobType.id === assignJobTypes[0]);
    setJobTypes(assignedJobTypes[0]);
    assignLabeler(assignedJobTypes[0]);
  }, [assignJobTypes]);

  const onClickJobType = (jobType) => {
    assignLabeler(jobType);
    clearSKU();
    handleJobTypeChange(jobType);
  };

  const refreshData = () => {
    setSelectedCrops([]);
    if (!isAutoLoadOn) {
      setData({});
      return;
    }
    assignLabeler(selectedJobType);
    setSelectedCrops([]);
  };

  const setSelectedCrop = (cropId) => {
    if (selectedCrops.includes(cropId)) {
      const updatedCrops = selectedCrops.filter((id) => id !== cropId);
      setSelectedCrops(updatedCrops);
    } else {
      setSelectedCrops([...selectedCrops, cropId]);
    }
  };

  const getSelectedUuid = (selectedLabel) => {
    const foundCrop = predefinedSet.find((crop) => crop.name === selectedLabel);
    if (foundCrop) {
      setSelectedUuid(foundCrop.id);
    } else {
      setSelectedUuid(null);
    }
    setSelectedLabel(selectedLabel);
  };

  const stopLoadingFunction = (value) => {
    setStopLoading(value);
  };

  const moveSelected = (toClusterId) => {
    const selected = selectedCrops;
    const crops = data.points;
    const newCrops = crops.map((el) => {
      if (selected.includes(el.id)) {
        return { ...el, clusterID: toClusterId };
      }
      return el;
    });
    setSelectedCrops([]);
    const { newCropsReCount, newClusterCount } = reCountClusterId(newCrops);
    const newData = {
      ...data,
      points: [...newCropsReCount],
      clusterCount: newClusterCount
    };
    setData(newData);
  };
  return (
    <Row>
      <div className="col-6">
        {data?.points && (
          <>
            {isRejected && <RejectErrorComponent id={data.id} />}
            {action === 'IDENTIFICATION' && (
              <CropLists
                onPreviewClicked={(crop) => {
                  setPreviewCrop(crop);
                }}
                setSelectedCrop={setSelectedCrop}
                selectedCrops={selectedCrops}
                crops={data.points || []}
                isRejected={isRejected}
              />
            )}
            {(action === 'CLEANING' || action === 'FAST_LANE') && (
              <CropDraftLists
                data={data}
                onPreviewClicked={(crop) => {
                  setPreviewCrop(crop);
                }}
                setSelectedCrop={setSelectedCrop}
                setSelectedCrops={setSelectedCrops}
                selectedCrops={selectedCrops}
                userType={userType}
                refreshData={refreshData}
                stopLoading={stopLoading}
                stopLoadingFunction={stopLoadingFunction}
                setData={setData}
                reCountClusterId={reCountClusterId}
                setErrorMessage={(message) => setErrorMessage(message)}
                isRejected={isRejected}
                action={action}
              />
            )}
          </>
        )}
        {!isAutoLoadOn && !data?.points && (
          <div>
            <h4>You are in auto-load off, if you want load next data please turn-on auto-load</h4>
          </div>
        )}
      </div>
      <div className="col-6">
        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
        <div
          style={{ position: 'absolute', top: '-70px', right: '0', zIndex: 1101 }}
          className="d-flex mb-2 justify-content-end"
        >
          {!isLabeler && action !== 'FAST_LANE' && (
            <Switch
              name={1}
              checked={isLabelerMode}
              setChecked={setLabelerMode}
              label="Labeler View Mode"
            />
          )}
        </div>
        <div style={{ position: 'sticky', top: '15px' }} className="card">
          <ClusterViewHeader
            data={data}
            userType={userType}
            labelerId={data.labeler_id}
            reviewerId={data.reviewer_id}
            clusterId={data.id}
            title={`Cluster ID: ${data.id || ''}`}
          />
          <Row className="mt-2">
            <Col xs={7}>
              <Row>
                <Col lg={9} md={12}>
                  {isLabeler ? (
                    <div className="text-center">Job Type: {assignJobTypes[0]}</div>
                  ) : (
                    <JobTypeDropdown
                      predefinedClass
                      selectedJobType={selectedJobType}
                      onClickJobType={(jobType) => onClickJobType(jobType)}
                      jobTypeList={jobTypes}
                    />
                  )}
                </Col>
                <Col lg={3} md={12}>
                  <Switch name={2} checked={isAutoLoadOn} setChecked={setAutoLoad} />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  {action === 'CLEANING' && (
                    <CleaningButtons
                      refreshData={refreshData}
                      stopLoading={stopLoading}
                      selectedCrops={selectedCrops}
                      userType={userType}
                      setErrorMessage={(message) => setErrorMessage(message)}
                      cluster={data}
                      stopLoadingFunction={stopLoadingFunction}
                      moveSelected={moveSelected}
                    />
                  )}
                  {action === 'IDENTIFICATION' && (
                    <IdentificationButtons
                      refreshData={refreshData}
                      prevSelectedLabelName={prevSelectedLabelName}
                      selectedCrops={selectedCrops}
                      stopLoadingFunction={stopLoadingFunction}
                      stopLoading={stopLoading}
                      userType={userType}
                      setErrorMessage={(message) => setErrorMessage(message)}
                      cluster={data}
                      selectedUuid={selectedUuid}
                    />
                  )}
                  {action === 'FAST_LANE' && (
                    <FastLaneButtons
                      cluster={data}
                      refreshData={refreshData}
                      setErrorMessage={(message) => setErrorMessage(message)}
                      selectedCrops={selectedCrops}
                      stopLoading={stopLoading}
                      stopLoadingFunction={stopLoadingFunction}
                      moveSelected={moveSelected}
                      selectedUuid={selectedUuid}
                    />
                  )}
                </Col>
              </Row>
            </Col>
            <Col className="d-flex justify-content-center" xs={5}>
              <CropPreview crop={previewCrop} />
            </Col>
          </Row>
          <Row>
            <Col>
              {action === 'FAST_LANE' && (
                <FastLaneGPT
                  cluster={data}
                  stopLoading={stopLoading}
                  prevSelectedLabelName={prevSelectedLabelName}
                  selectedLabel={selectedLabel}
                  setSelectedUuid={setSelectedUuid}
                  setSelectedLabel={setSelectedLabel}
                />
              )}
            </Col>
          </Row>
          {data && data.id && (
            <div className="mb-2">
              <div className="container border-gray">
                <CropProducts
                  setLabelName={(labelName) => setPrevSelectedLabelName(labelName)}
                  predefinedSet={predefinedSet}
                  selectedJobType={selectedJobType}
                  selectedSku={selectedSku}
                  onChange={(selectedLabel) => getSelectedUuid(selectedLabel)}
                  selectedLabel={selectedLabel}
                  prevSelectedUuid={data.brandbank_uuid}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Row>
  );
}

export default ClusterWrapper;
