import React, { useEffect, useState } from 'react';

import { Button, Col, ListGroup, Modal, Row, Spinner, Card } from 'react-bootstrap';
import ListPaginator, { PAGE_SIZE } from '../../components/Paginators/ListPaginator';
import { getPredefinedClass } from '../../api/brandbank';
import { useBatchLabelingContext } from '../../contexts/BatchLabelingProcess';
import { getGPTProposals } from '../../api/clusters';

function ButtonSpinner() {
  return <Spinner size="sm" animation="border" role="status" />;
}

function GPTModalComponent({
  show,
  setShow,
  selectedLabel,
  setSelectedUuid,
  setSelectedLabel,
  clusterId
}) {
  const [isLoading, setIsLoadig] = useState(true);
  const { loadPredefinedClass, selectedJobType } = useBatchLabelingContext();
  const [state, setState] = React.useState({
    count: 0,
    page: 1,
    results: []
  });

  const setRequestParameters = ({ page = state.page }) => ({
    page,
    page_size: PAGE_SIZE
  });

  const loadLabelsName = async (dataResults) => {
    const labels = [];
    await Promise.all(
      dataResults.map(async (bb_uuid) => {
        await getPredefinedClass({
          predefined_classes: selectedJobType.predefined_classes_id,
          entity_id: bb_uuid.brandbank_uuid,
          page: 1,
          page_size: PAGE_SIZE
        })
          .then(async (response) => {
            if (!response.ok) {
              throw new Error(`HTTP error: ${response.status}`);
            }
            return response.json();
          })
          .then((source) => {
            labels.push({
              ...bb_uuid,
              name: source.results[0].name,
              photos: source.results[0].photos
            });
          })

          .catch((err) => {
            console.error(`Fetch problem: ${err.message}`);
          });
      })
    );
    return labels;
  };

  const loadGPTProposals = async (parameters) => {
    const params = setRequestParameters(parameters);
    getGPTProposals(clusterId, parameters)
      .then((response) => response.json())
      .then(async (data) => {
        const newResults = await loadLabelsName(data.results);
        setState((prev) => ({ ...prev, ...data, results: [...newResults], page: params.page }));
        setIsLoadig(false);
      });
  };

  React.useEffect(() => {
    if (show) loadGPTProposals({});
  }, [show]);
  const listStyle = {
    height: '320px',
    overflowY: 'scroll'
  };
  const ListItemStyle = { padding: '0 0 0 15px' };

  const onClick = (item) => {
    loadPredefinedClass(item.brandbank_uuid, 1, 'entity_id');
    setSelectedLabel(item.name);
    setSelectedUuid(item.brandbank_uuid);
  };

  const handleClose = () => setShow(false);

  return (
    <Modal size='lg' centered show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Proposed other labels</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <Row>
          <Col md="12">
            {state.count > PAGE_SIZE && (
              <ListPaginator
                total={state.count}
                current={state.page}
                previous={state.previous}
                next={state.next}
                changePage={loadGPTProposals}
              />
            )}
            {isLoading ? (
              <ButtonSpinner />
            ) : (
              <div>
                {state.results.length > 0 ? (
                    <Card>
                  <ListGroup variant="flush" style={listStyle}>
                    {state.results.map((item) => (
                      <ListGroup.Item key={item.uuid} style={ListItemStyle}>
                        <div
                          onClick={() => onClick(item)}
                          className="row p-3"
                          style={
                            item.name !== selectedLabel
                              ? { marginRight: 0, cursor: 'pointer' }
                              : { marginRight: 0, cursor: 'pointer', background: '#C1E97C' }
                          }
                        >
                          <div className="col-12 col-md-9">
                            {item.name}
                          </div>
                          <div className="col-12 col-md-3 text-center">
                              <img src={item.photos[0].url} alt="" style={{maxWidth: '100px', maxHeight: '100px'}}/>

                          </div>
                        </div>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                    </Card>
                ) : (
                  <>Nothing found</>
                )}
              </div>
            )}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function FastLaneGPT({
  cluster,
  stopLoading,
  prevSelectedLabelName,
  selectedLabel,
  setSelectedUuid,
  setSelectedLabel
}) {
  const { id } = cluster;
  const [showModalGPT, setShowModalGPT] = useState(false);
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    setIsBusy(stopLoading);
  }, [stopLoading]);

  return (
    <>
      {id && (
        <div className="p-3">
          {cluster.brandbank_uuid ? (
              <>
                {`Proposed BB UUID: ${cluster.brandbank_uuid}`}
                <br />
                {prevSelectedLabelName ? (
                    <>Proposed main label: {prevSelectedLabelName}</>
                ) : (
                    <>Proposed main label: <ButtonSpinner/></>
                )}
              </>
          ) : (
              <>
                Proposed BB UUID: 'unknown'
                <br />
                Proposed main label: 'unknown'
              </>
          )}

          <br />
          <Button
            style={{ fontSize: '13px' }}
            className="mt-2"
            variant="defoult"
            disabled={!cluster.brandbank_uuid || isBusy}
            onClick={() => setShowModalGPT(true)}
          >
            Proposed other label(s)
          </Button>
        </div>
      )}
      <GPTModalComponent
        show={showModalGPT}
        setShow={setShowModalGPT}
        selectedLabel={selectedLabel}
        setSelectedUuid={setSelectedUuid}
        setSelectedLabel={setSelectedLabel}
        clusterId={id}
      />
    </>
  );
}

export default FastLaneGPT;
