import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-bootstrap';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { useBatchLabelingContext } from 'contexts/BatchLabelingProcess';

function ProductImage({ photo, imageHash }) {
  const defaultPhoto = require('assets/img/question-mark_2753.png').default;
  const photoSrc = photo ? `${photo}?${imageHash}` : defaultPhoto;

  return (
    <div style={{ padding: '1px' }} className="maintxt my-2 mx-auto d-block">
      <img
        alt=""
        src={photoSrc}
        className="maintxt my-2 mx-auto d-block"
        style={{
          maxHeight: '150px',
          maxWidth: '150px'
        }}
      />
    </div>
  );
}

export function SingleSKUReferenceItem({ name, selectedLabel, onClick, photos }) {
  const [imageHash, reloadImageHash] = React.useState(Date.now().toString());
  const photo = photos[0];
  const hasMorePhotos = photos.length > 1;

  const { getSelectedLabel } = useBatchLabelingContext();

  useEffect(() => {
    if (name === selectedLabel) {
      getSelectedLabel(selectedLabel);
    }
  }, [name, selectedLabel]);

  const buttonStyle = {
    position: 'absolute',
    border: 'none',
    right: '0',
    zIndex: '100'
  };
  const selectedStyle =
    name !== selectedLabel
      ? { marginRight: 0, cursor: 'pointer' }
      : { marginRight: 0, cursor: 'pointer', background: '#C1E97C' };

  const reloadImage = (e) => {
    reloadImageHash(Date.now().toString());
    e.stopPropagation();
  };

  return (
    <div onClick={() => onClick(name)} className="row" style={selectedStyle}>
      <div style={{ display: 'flex', alignItems: 'center' }} className="col-xl-9">
        <p>{name}</p>
      </div>
      <div className="col-xl-3">
        <button
          className="nc-icon nc-refresh-02 btn pull-right"
          type="button"
          style={buttonStyle}
          onClick={(e) => reloadImage(e)}
        />

        {!hasMorePhotos ? (
          <LazyLoadComponent threshold={50} delayTime={10}>
            <ProductImage photo={photo?.url} imageHash={imageHash} />
          </LazyLoadComponent>
        ) : (
          <Carousel variant="dark" indicators={false} slide={false}>
            {photos &&
              photos.map((productPhoto) => (
                <Carousel.Item key={productPhoto.id}>
                  <ProductImage photo={productPhoto?.url} imageHash={imageHash} />
                </Carousel.Item>
              ))}
          </Carousel>
        )}
      </div>
    </div>
  );
}

SingleSKUReferenceItem.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func,
  selectedLabel: PropTypes.string,
  photos: PropTypes.array
};

ProductImage.propTypes = {
  photo: PropTypes.string,
  imageHash: PropTypes.string
};

SingleSKUReferenceItem.defaultProps = {
  name: '',
  selectedLabel: '',
  onClick: () => {},
  photos: []
};

ProductImage.defaultProps = {
  photo: undefined,
  imageHash: undefined
};

export default SingleSKUReferenceItem;
