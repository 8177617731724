import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from 'react-bootstrap';

function ButtonGroupSorting({ sortingEl, onChangeSorting, selectedSorting, isLoading }) {
  return (
    <div className="d-flex align-items-center">
      <span className={isLoading ? 'text-muted' : undefined}>{sortingEl.label}</span>
      <ButtonGroup className="ml-2 mr-4">
        <Button
          variant="secondary"
          disabled={isLoading}
          className={selectedSorting === sortingEl.valueAsc ? 'btn-fill' : undefined}
          onClick={() => onChangeSorting(sortingEl.valueAsc)}
          style={{ height: 50 }}
        >
          <i className="fa fa-sort-amount-down-alt" />
        </Button>
        <Button
          variant="secondary"
          disabled={isLoading}
          className={selectedSorting === sortingEl.valueDes ? 'btn-fill' : undefined}
          onClick={() => onChangeSorting(sortingEl.valueDes)}
          style={{ height: 50 }}
        >
          <i className="fa fa-sort-amount-up" />
        </Button>
      </ButtonGroup>
    </div>
  );
}

ButtonGroupSorting.propTypes = {
  sortingEl: PropTypes.object,
  onChangeSorting: PropTypes.func,
  selectedSorting: PropTypes.string,
  isLoading: PropTypes.bool
};

ButtonGroupSorting.defaultProps = {
  sortingEl: {},
  onChangeSorting: () => {},
  selectedSorting: '',
  isLoading: false
};

export default ButtonGroupSorting;
