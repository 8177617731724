import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getJobTypes } from 'api/jobTypes';
import { Card, Col, Row } from 'react-bootstrap';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import Select from 'react-select';
import SpinnerButton from 'components/Buttons/SpinnerButton';
import { getLabelers } from 'api/user';
import { useUserContext } from 'contexts/Users';

function PerformanceFilters({ filterCallback, getLabelersList, isLoading }) {
  const { isLabeler, isQC, userInfo } = useUserContext();
  const [state, setState] = useState({
    jobTypeList: [],
    jobsOptions: []
  });

  const [selectedJobType, setSelectedJobType] = useState([]);
  const [labelers, setLabelers] = useState([{}]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [lastStateChangeBefore, setlastStateChangeBefore] = useState(null);
  const [lastStateChangeAfter, setlastStateChangeAfter] = useState(null);
  const [selectedLabeler, setSelectedLabeler] = useState(
    isLabeler || (isQC && { value: userInfo.id, label: `${userInfo.username}` }) || null
  );
  const [selectedType, setSelectedType] = useState(null);
  const [checked, setChecked] = useState(false);

  React.useEffect(() => {
    let isMounted = true;
    getJobTypes().then(async (response) => {
      if (isMounted) {
        const data = await response.json();
        const jobTypeList = data.results;
        const jobs = [];
        jobTypeList.forEach((el) => {
          jobs.push({ value: el.id, label: `${el.name}-${el.id}` });
        });
        setState((prev) => ({ ...prev, jobTypeList, jobsOptions: jobs }));
      }
    });

    getLabelers().then(async (response) => {
      if (isMounted) {
        const data = await response.json();
        const lebelersList = data.results;
        const lebelers = [];
        lebelersList.forEach((labeler) => {
          lebelers.push({ value: labeler.id, label: `${labeler.user.username}-${labeler.id}` });
        });
        setLabelers(lebelers);
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  const typeOptions = [
    { value: 'jobs', label: 'Job' },
    { value: 'crop_jobs', label: 'Crop Job' }
  ];

  const onClick = () => {
    filterCallback({
      selectedType: selectedType?.value,
      selectedJobType: selectedJobType?.value,
      selectedLabeler: selectedLabeler?.value,
      lastStateChangeBefore,
      lastStateChangeAfter,
      checked
    });
    getLabelersList(labelers);
  };

  const setDate = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setlastStateChangeBefore(endDate);
    setlastStateChangeAfter(startDate);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minWidth: 180,
      maxWidth: 850,
      minHeight: 50
    })
  };

  const { jobsOptions } = state;
  const isDisabled = isLoading || !selectedType || !startDate || !endDate;

  return (
    <Col md="12">
      <Card className="strpied-tabled-with-hover pb-2">
        <Card.Header>
          <Card.Title as="h4">Filters:</Card.Title>
        </Card.Header>
        <Card.Body className="table-full-width table-responsive px-3">
          <Row>
            <Select
              defaultValue={selectedJobType}
              styles={customStyles}
              onChange={setSelectedJobType}
              options={jobsOptions}
              placeholder="Select Job Type"
              isSearchable
              isClearable
              className="mr-3 ml-3"
            />
            <Select
              defaultValue={selectedType}
              styles={customStyles}
              onChange={setSelectedType}
              options={typeOptions}
              placeholder="Select type"
              isSearchable
              isClearable
              className="mr-3"
            />
            <Select
              value={selectedLabeler}
              styles={customStyles}
              onChange={setSelectedLabeler}
              options={labelers}
              isDisabled={isLabeler || isQC}
              placeholder="Select Labelers"
              isSearchable
              className="mr-3"
              required
              isClearable
            />
            <DateRangePicker setDate={setDate} />
            <div
              style={{ height: '50px' }}
              className="d-flex justify-content-center align-items-center 
            custom-control custom-switch custom-switch-md mr-3"
            >
              <input
                type="checkbox"
                className="custom-control-input"
                id="customSwitch1"
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
              />
              <label className="custom-control-label" htmlFor="customSwitch1">
                <div style={{ marginTop: '5px' }}>ImageFinder Job</div>
              </label>
            </div>
            <SpinnerButton
              style={{ height: '50px' }}
              isLoading={isLoading}
              disabled={isDisabled}
              onClick={onClick}
              value="search"
            />
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
}

PerformanceFilters.propTypes = {
  filterCallback: PropTypes.func,
  getLabelersList: PropTypes.func,
  isLoading: PropTypes.bool
};

PerformanceFilters.defaultProps = {
  filterCallback: () => {},
  getLabelersList: () => {},
  isLoading: false
};

export default PerformanceFilters;
